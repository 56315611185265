<template>
  <div>
    <prozess-search-dropdown
      v-model="data"
      :placeholder="$t('Select group or leave blank')"
      :loading="loading"
      :can-handle-new-item="false"
      :options="items"
      icon="UsersIcon"
      :error="error"
      :hint="hint"
      :disabled="disabled"
      @search="handleSearch"
      @clear="handleClear"
      @blur="handleBlur"
    />
  </div>
</template>

<script>
import groupService from '@/services/group'

export default {
  props: {
    label: {
      default: null,
      type: String,
    },
    value: {
      default: null,
      type: [String, Number, Object],
    },
    hint: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: this.value,
      items: [],
      loading: false,
    }
  },
  watch: {
    data() {
      this.$emit('input', this.data)
    },
    value(data) {
      this.data = data
    },
  },
  methods: {
    handleClear() {
      this.items = []
      this.$emit('input', null)
    },
    handleBlur() {
      this.items = []
    },
    async handleSearch(search) {
      if (search.trim() === '') return
      this.loading = true
      const { response } = await this.$async(groupService.getAll({
        q: search.trim(),
      }))
      this.items = response.data.pageItems.map(item => ({
        label: item.name,
        value: item.uuidKey,
      }))
      this.loading = false
    },
  },
}
</script>
